@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inconsolata:wght@200;300;400;500&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

.text-grey {
    color: #777e91;
}

.bg-black-gradient {
    background: linear-gradient(
      144.39deg,
      #ffffff -278.56%,
      #6d6d6d33 -78.47%,
      #11101d3b 91.61%
    );
  }

  .bg-beige-gradient {
    background: rgb(255,255,255);
    background: linear-gradient(124deg, rgba(255,255,255,1) 0%, rgb(255, 229, 182) 35%, rgba(255, 201, 101, 0.797) 100%); 
  }

  .label {
    background: #FFCB66;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 6px 12px;
    gap: 10px;
    width: 98px;
    height: 24px;
    border-radius: 4px;
  }

  .card {
    background: linear-gradient(180deg, rgba(255, 236, 208, 0.2) 0%, rgba(240, 183, 98, 0.11) 31.76%, rgba(252, 215, 161, 0.11) 31.77%, rgba(240, 183, 98, 0.11) 57.81%, rgba(240, 183, 98, 0.11) 57.82%, rgba(240, 183, 98, 0.11) 84.37%, rgba(237, 166, 59, 0.11) 84.39%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
  }